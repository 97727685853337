import { useLanguage } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { Link } from '/features/buildingBlocks/Link'
import { routeMap } from '/routeMap'

import styles from './Logo.css'

import logo from '/images/logo.raw.svg'
import logoFooter from '/images/logo-footer.raw.svg'

export function Logo({ layoutClassName = undefined, dataX }) {
  return <LogoBase {...{ logo, layoutClassName, dataX }} />
}

export function LogoFooter({ layoutClassName }) {
  return (
    <LogoBase
      logo={logoFooter}
      dataX='link-in-footer-to-home'
      {...{ layoutClassName }}
    />
  )
}

function LogoBase({ logo, layoutClassName, dataX }) {
  const language = useLanguage()

  return (
    <Link href={routeMap.app.home({ language })} layoutClassName={cx(styles.componentBase, layoutClassName)} {...{ dataX }}>
      <Icon icon={logo} label='Digital Power' layoutClassName={styles.icon} />
    </Link>
  )
}
