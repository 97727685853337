import { LinkUnderlinedOnInteraction } from '/features/buildingBlocks/Link'

import styles from './LanguageSwitch.css'

export function LanguageSwitch({ layoutClassName, linksToTranslations }) {
  return (
    <nav className={cx(styles.component, layoutClassName)}>
      {linksToTranslations.map((x, i) => (
        <div key={i} className={cx(styles.availableLanguage, x.isActive && styles.isActive)}>
          <LinkUnderlinedOnInteraction href={x.link} dataX={`link-in-footer-to-${x.language}`}>
            {x.language}
          </LinkUnderlinedOnInteraction>
        </div>
      ))}
    </nav>
  )
}
